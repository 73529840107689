/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { getArrangedUselimits } from "./utils";
import FunctionIndividualSettingList from "../../components/FunctionManagementPage/IndividualSettingList";
import { ModalEditUseLimit } from "../../components/FunctionManagementPage/ModalEditUseLimit";
import { ModalConfirm } from "../../components/ModalConfirm";
import { ArrangedUseLimitsData } from "../../models/FeatureUseLimitsData";
import DataSvc from "../../services/dataSvc";
import "./styles.scss";

interface IFilterFormData {
  searchKeyword: string;
  numberPerPage: number;
  pageIndex: number;
}

type IFunctionManagementPageProps = RouteComponentProps<any>;

const FunctionManagementPage: React.FunctionComponent<
  IFunctionManagementPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`functionManagementPage.${key}`);

  const [targetType, setTargetType] = useState<string>("org");
  const [assignUseLimitData, setAssignUseLimitData] = useState<any>();
  const [showModalEditFeatures, setShowModalEditFeatures] =
    useState<boolean>(false);
  const [showModaConfirmDeleteUseLimits, setShowModaConfirmDeleteUseLimits] =
    useState<boolean>(false);

  const defaultFilterFormData = {
    searchKeyword: "",
    numberPerPage: 20,
    pageIndex: 1,
  };

  const [filterFormData, setFilterFormData] = useState<IFilterFormData>(
    defaultFilterFormData
  );

  const { data: useLimitsData, refetch: refetchingUseLimits } = useQuery(
    ["features-use-limits", targetType, filterFormData],
    () => {
      return DataSvc.getUseLimits({
        keyword: filterFormData.searchKeyword,
        limit: filterFormData.numberPerPage,
        offset: (filterFormData.pageIndex - 1) * filterFormData.numberPerPage,
        target: targetType,
      });
    }
  );

  const deleteUseLimits = useMutation(() => {
    if (assignUseLimitData?.target === "org") {
      return DataSvc.deleteOrgUseLimits(assignUseLimitData.target_id);
    }
    return DataSvc.deleteUserUseLimits(assignUseLimitData.target_id);
  });

  const [arrangedUseLimitsData, setArrangedUseLimitsData] = useState<{
    arrangedUseLimits: ArrangedUseLimitsData[];
    totalCount: number;
  }>({ arrangedUseLimits: [], totalCount: 0 });

  useEffect(() => {
    if (useLimitsData) {
      const arrangedUseLimits = getArrangedUselimits(
        useLimitsData.list,
        targetType
      );
      setArrangedUseLimitsData({
        arrangedUseLimits: arrangedUseLimits,
        totalCount: useLimitsData.totalCount,
      });
    }
  }, [useLimitsData]);

  const switchingTargertType = () => {
    if (targetType === "org") {
      setTargetType("user");
    } else {
      setTargetType("org");
    }
    setFilterFormData(defaultFilterFormData);
    setArrangedUseLimitsData({ arrangedUseLimits: [], totalCount: 0 });
  };

  const onDeleteUseLimits = async () => {
    const data = await deleteUseLimits.mutateAsync();
    if (data.status === 200) {
      setShowModaConfirmDeleteUseLimits(false);
      setShowModalEditFeatures(false);
      refetchingUseLimits();
    }
  };

  return (
    <>
      <div className="right-content function-management">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{`${t("function_management")} (${
            targetType === "org" ? t("organization") : t("user")
          })`}</div>
          <button className="right btn btn-blue" onClick={switchingTargertType}>
            {targetType === "org"
              ? t("switch_to_user_features")
              : t("switch_to_org_features")}
          </button>
          <div className="line"></div>
        </div>

        <div className="individual-settings">
          {targetType === "org" ? t("organization") : t("user")}
        </div>
        {!!arrangedUseLimitsData && (
          <FunctionIndividualSettingList
            arrangedUseLimits={arrangedUseLimitsData.arrangedUseLimits}
            targetType={targetType}
            totalCount={arrangedUseLimitsData.totalCount}
            filterFormData={filterFormData}
            onChangeFilterFormData={(filterFormData: any) => {
              setFilterFormData(filterFormData);
            }}
            onClickEdit={(useLimit: ArrangedUseLimitsData) => {
              setAssignUseLimitData(useLimit);
              setShowModalEditFeatures(true);
            }}
          />
        )}

        {showModalEditFeatures && (
          <ModalEditUseLimit
            arrangeUseLimits={assignUseLimitData}
            onClose={() => {
              setShowModalEditFeatures(false);
            }}
            onRefresh={() => {
              refetchingUseLimits();
            }}
          />
        )}

        {showModaConfirmDeleteUseLimits && (
          <ModalConfirm
            title={"please_confirm"}
            cancelLabel={"cancel"}
            confirmLabel={"confirm"}
            onClose={() => {
              setShowModaConfirmDeleteUseLimits(false);
            }}
            onConfirm={() => {
              onDeleteUseLimits();
              setShowModaConfirmDeleteUseLimits(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(FunctionManagementPage);
