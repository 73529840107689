import { Buffer } from "buffer";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ModalPreviewLocalPost } from "./ModalPreviewLocalPost";
import { LocationData } from "../../../models/LocationData";
import DataSvc from "../../../services/dataSvc";
import { checkFormatUrl } from "../../../utils/formatValidation";
import { createNestedFormData } from "../../../utils/formData";
import { DateTimeInterval } from "../../FormElement/DateTimeIntervalInput/utils";
import ModalConfirm from "../../ModalConfirm";
import { CreateLocalPostForm } from "../CreateLocalPostForm";
import {
  ActionType,
  CallToAction,
  CreateLocalPostState,
  EventScheduleError,
  LocalPostTopicType,
} from "../CreateLocalPostForm/utils";
import { ModalLocalPostIdeas } from "../ModalLocalPostIdeas";

import "./styles.scss";

export interface IModalCreateLocalPostProps {
  locationData: LocationData | undefined;
  locationId: string;
  refetch: () => void;
  onClose: () => void;
}

export const ModalCreateLocalPost: React.FunctionComponent<
  IModalCreateLocalPostProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateLocalPost.${key}`);

  const { locationData, onClose, locationId, refetch } = props;

  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);

  const [shownModalPreview, setShownModalPreview] = useState<boolean>(false);

  const [shownModalLocalPostIdeas, setShownModalLocalPostIdeas] =
    useState<boolean>(false);

  const [localPostState, setLocalPostState] = useState<CreateLocalPostState>({
    media: [],
    text: "",
    messageTextError: undefined,
    languageCode: "ja",
    callToAction: {
      actionType: ActionType.ACTION_TYPE_UNSPECIFIED,
      url: "",
      urlError: undefined,
    },
    topicType: LocalPostTopicType.STANDARD,
    event: {
      title: "",
      schedule: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
      },
      scheduleError: {
        dateError: undefined,
        timeError: undefined,
      },
    },
    offer: {
      couponCode: "",
      redeemOnlineUrl: "",
      termsConditions: "",
    },
  });

  const isEnabledBtn = () => {
    switch (localPostState.topicType) {
      case LocalPostTopicType.LOCAL_POST_TOPIC_TYPE_UNSPECIFIED:
        return false;
      case LocalPostTopicType.STANDARD:
        return (
          localPostState.messageTextError === undefined &&
          (localPostState.text !== "" || localPostState.media.length > 0) &&
          (localPostState.callToAction.urlError === undefined ||
            localPostState.callToAction.actionType ===
              ActionType.ACTION_TYPE_UNSPECIFIED ||
            localPostState.callToAction.actionType === ActionType.CALL)
        );
      case LocalPostTopicType.EVENT:
        return (
          localPostState.messageTextError === undefined &&
          (localPostState.text !== "" || localPostState.media.length > 0) &&
          (localPostState.callToAction.urlError === undefined ||
            localPostState.callToAction.actionType ===
              ActionType.ACTION_TYPE_UNSPECIFIED ||
            localPostState.callToAction.actionType === ActionType.CALL) &&
          localPostState.event.title !== "" &&
          localPostState.event.titleError === undefined &&
          localPostState.event.schedule.startDate !== undefined &&
          localPostState.event.schedule.endDate !== undefined &&
          localPostState.event.scheduleError.dateError === undefined &&
          localPostState.event.scheduleError.timeError === undefined
        );
      case LocalPostTopicType.OFFER:
        return (
          localPostState.messageTextError === undefined &&
          (localPostState.text !== "" || localPostState.media.length > 0) &&
          localPostState.event.title !== "" &&
          localPostState.event.titleError === undefined &&
          localPostState.event.schedule.startDate !== undefined &&
          localPostState.event.schedule.endDate !== undefined &&
          localPostState.event.scheduleError.dateError === undefined &&
          localPostState.offer.couponCodeError === undefined &&
          localPostState.offer.redeemOnlineUrlError === undefined &&
          localPostState.offer.termsAndConditionsError === undefined
        );
      case LocalPostTopicType.ALERT:
        return false;
    }
  };

  const validateBody = (body: string, files: File[]): string | undefined => {
    let error: string | undefined = undefined;
    if (body.length > 1500) {
      error = t("createLocalPostForm.errors.message_too_long");
    }
    if (body.trim().length < 1 && files.length === 0) {
      error = t("createLocalPostForm.errors.message_too_short");
    }
    return error;
  };

  const validateCallToActionUrl = (cta: CallToAction): string | undefined => {
    if (
      cta.actionType === ActionType.ACTION_TYPE_UNSPECIFIED ||
      cta.actionType === ActionType.CALL
    ) {
      return undefined;
    }

    if (!checkFormatUrl(cta.url)) {
      return t("createLocalPostForm.errors.url");
    }

    return undefined;
  };

  const changeMedia = (files: File[]) => {
    const bodyError = validateBody(localPostState.text, files);
    setLocalPostState((prevState) => ({
      ...prevState,
      messageTextError: bodyError,
      media: files,
    }));
  };

  const changeMediaError = (error: string | undefined) => {
    setLocalPostState((prevState) => ({
      ...prevState,
      mediaError: error,
    }));
  };

  const changeBody = (value: string) => {
    const error = validateBody(value, localPostState.media);
    setLocalPostState((prevState) => ({
      ...prevState,
      text: value,
      messageTextError: error,
    }));
  };

  const changeCallToActionType = (value: ActionType) => {
    setLocalPostState((prevState) => ({
      ...prevState,
      callToAction: {
        ...prevState.callToAction,
        actionType: value,
        urlError: validateCallToActionUrl({
          actionType: value,
          url: localPostState.callToAction.url,
        }),
      },
    }));
  };

  const changeCallToActionUrl = (value: string) => {
    setLocalPostState((prevState) => ({
      ...prevState,
      callToAction: {
        ...prevState.callToAction,
        url: value,
        urlError: validateCallToActionUrl({
          actionType: localPostState.callToAction.actionType,
          url: value,
        }),
      },
    }));
  };

  const changeTopicType = (value: LocalPostTopicType) => {
    ReactGA.event({
      category: "create-local-post",
      action: "Change Topic Type",
      label: value,
    });
    setLocalPostState((prevState) => {
      const newState = { ...prevState };
      newState.topicType = value;

      return newState;
    });
  };

  const changeEventTitle = (value: string) => {
    setLocalPostState((prevState) => ({
      ...prevState,
      event: {
        ...prevState.event,
        title: value,
        titleError:
          value.length < 1
            ? t("createLocalPostForm.errors.event.title_too_short")
            : value.length >= 58
            ? t("createLocalPostForm.errors.event.title_too_long")
            : undefined,
      },
    }));
  };

  const changeEventSchedule = (value: DateTimeInterval) => {
    setLocalPostState((prevState) => {
      const newSchedule = { ...value };

      // Delete these keys entirely if undefined so the backend can skip validation
      if (newSchedule.startTime === undefined) {
        delete newSchedule.startTime;
      }
      if (newSchedule.endTime === undefined) {
        delete newSchedule.endTime;
      }

      return {
        ...prevState,
        event: {
          ...prevState.event,
          schedule: newSchedule,
        },
      };
    });
  };

  const changeEventScheduleError = (value: EventScheduleError) => {
    setLocalPostState((prevState) => {
      const newError = { ...value };
      return {
        ...prevState,
        event: {
          ...prevState.event,
          scheduleError: newError,
        },
      };
    });
  };

  const changeOfferCouponCode = (value: string) => {
    setLocalPostState((prevState) => ({
      ...prevState,
      offer: {
        ...prevState.offer,
        couponCode: value,
        couponCodeError:
          value.length >= 58
            ? t("createLocalPostForm.errors.offer.coupon_code_too_long")
            : undefined,
      },
    }));
  };

  const changeOfferRedeemOnlineUrl = (value: string) => {
    setLocalPostState((prevState) => ({
      ...prevState,
      offer: {
        ...prevState.offer,
        redeemOnlineUrl: value,
        redeemOnlineUrlError:
          value === ""
            ? undefined
            : checkFormatUrl(value)
            ? undefined
            : t("createLocalPostForm.errors.offer.url"),
      },
    }));
  };

  const changeOfferTermsAndConditions = (value: string) => {
    setLocalPostState((prevState) => ({
      ...prevState,
      offer: {
        ...prevState.offer,
        termsConditions: value,
        termsAndConditionsError:
          value.length > 5000
            ? t(
                "createLocalPostForm.errors.offer.terms_and_conditions_too_long"
              )
            : undefined,
      },
    }));
  };

  const generateTextWithAI = async (
    localPostTopicType?: LocalPostTopicType,
    extraInputTitle?: string,
    startDate?: Dayjs,
    endDate?: Dayjs
  ) => {
    const media = localPostState.media[0];
    const mediaBuffer = await media.arrayBuffer();
    const imageDataString = Buffer.from(mediaBuffer).toString("base64");

    const data: any = {
      location_id: Number(locationId),
      mime_type: media.type,
      image_data_string: imageDataString,
    };

    if (localPostTopicType) {
      data.topic = localPostTopicType;
      data.extra_information = {
        title: extraInputTitle,
        start_date: startDate?.format("YYYY/MM/DD HH:mm"),
        end_date: endDate?.format("YYYY/MM/DD HH:mm"),
      };
    }

    ReactGA.event({
      category: "create-local-post",
      action: "Generate description with AI",
      label: localPostTopicType,
    });

    const response = await DataSvc.generateLocalPostTextWithGemini(data);
    setLocalPostState({
      ...localPostState,
      text: response, // Gemini sometimes returns strings with a backslashes
    });
  };

  const post = async () => {
    const { media, ...data } = localPostState;

    const formData = createNestedFormData(data);

    localPostState.media.forEach((_media) => {
      formData.append("media", _media);
    });

    ReactGA.event("Generate description with AI", {
      category: "create-local-post",
      label: data.topicType,
      hasMedia: media.length > 0,
      hasText: data.text.length > 0,
      callToActionType: data.callToAction.actionType,
    });

    await DataSvc.createLocalPost(locationId, formData);

    toast.success(t("post_created"));
    refetch();
    onClose();
  };

  return (
    <div className="modal modal-default modal-create-local-post">
      <div className="modal-mains">
        <button
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></button>
        <div className="modal-mains__body">
          <div className="top-title">
            <h2>{t("new_post")}</h2>
            {false && (
              <button
                className="btn btn-border"
                onClick={() => setShownModalLocalPostIdeas(true)}
              >
                {t("get_ideas")}
              </button>
            )}
          </div>
          <CreateLocalPostForm
            locationData={locationData}
            onChangeFiles={changeMedia}
            onChangeMediaError={changeMediaError}
            onChangeBody={changeBody}
            onChangeCallToActionType={changeCallToActionType}
            onChangeUrl={changeCallToActionUrl}
            onChangeTopicType={changeTopicType}
            onChangeEventTitle={changeEventTitle}
            onChangeEventSchedule={changeEventSchedule}
            onChangeEventScheduleError={changeEventScheduleError}
            onChangeOfferCouponCode={changeOfferCouponCode}
            onChangeOfferRedeemOnlineUrl={changeOfferRedeemOnlineUrl}
            onChangeOfferTermsAndConditions={changeOfferTermsAndConditions}
            onTriggerGenerateText={generateTextWithAI}
            {...localPostState}
          />
          <div className="create-local-post-warning">
            <span>{t("post_warning")}</span>
          </div>
          <div className="bottom-btns">
            <button className="btn btn-border" onClick={onClose}>
              {t("cancel")}
            </button>
            <div>
              <button
                className={`btn btn-border ${isEnabledBtn() ? "" : "disabled"}`}
                onClick={() => {
                  setShownModalPreview(true);
                }}
              >
                {t("preview")}
              </button>
              <button
                className={`btn btn-blue ${isEnabledBtn() ? "" : "disabled"}`}
                onClick={() => {
                  setShownModalConfirm(true);
                }}
              >
                {t("create")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {shownModalPreview && (
        <ModalPreviewLocalPost
          onClose={() => {
            setShownModalPreview(false);
          }}
          localPost={localPostState}
          locationData={locationData}
          setShownModalConfirm={setShownModalConfirm}
        />
      )}
      {shownModalLocalPostIdeas && (
        <ModalLocalPostIdeas
          locationId={locationId}
          onClose={() => setShownModalLocalPostIdeas(false)}
        />
      )}
      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_create_local_post"}
          cancelLabel={"cancel"}
          confirmLabel={"post"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            post();
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};
